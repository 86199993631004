import React from "react";
import {IntlProvider} from "react-intl";
import Cookies from 'universal-cookie';
import {ContextLayout} from "utility/context/Layout"

import messages_ir from "../../assets/data/locales/ir.json"

const menu_messages = {
    fa: messages_ir,
}

const Context = React.createContext();

class IntlProviderWrapper extends React.Component {
    constructor(props) {
        super(props);
        const cookies = new Cookies();
        if (!cookies.get('language'))
            cookies.set('language', "fa", {path: '/'});
        this.state = {
            locale: cookies.get('language') ? cookies.get('language') : "fa",
            messages: cookies.get('language') ? menu_messages[cookies.get('language')] : menu_messages["fa"]
        }
    }

    render() {
        const cookies = new Cookies();
        const {children} = this.props;
        const {locale, messages} = this.state;

        return (
            <ContextLayout.Consumer>
                {context => {
                    return (
                        <Context.Provider
                            value={{
                                state: this.state,
                                switchLanguage: language => {
                                    cookies.set('language', language, {path: '/'});
                                    if (language === "en")
                                        context.switchDir("ltr")
                                    else if (language === "fa")
                                        context.switchDir("rtl")

                                    this.setState({
                                        locale: language,
                                        messages: menu_messages[language]
                                    })
                                }
                            }}>

                            <IntlProvider
                                key={locale}
                                locale={locale}
                                messages={messages}
                                defaultLocale={cookies.get('language') ? cookies.get('language') : "fa"}
                            >
                                {children}
                            </IntlProvider>
                        </Context.Provider>
                    )
                }}
            </ContextLayout.Consumer>
        )
    }
}

export {IntlProviderWrapper, Context as IntlContext}
