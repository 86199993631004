import Axios from "axios";
import {baseUrl} from "../../configs/requestConfig";
import Cookies from "universal-cookie";

const initialHeader = {}
export default async function GetRequest(url, header = initialHeader, locale = false) {
    const cookies = new Cookies();
    if (locale)
        header['locale'] = cookies.get('locale');
    else
        header['locale'] = cookies.get('language');
    const config = {
        headers: header,
        withCredentials: true
    }
    return await Axios.get(baseUrl + url, config);
}
