import jwtDecode from "jwt-decode";

const initialState = {
    token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
    user_id: '',
    name: '',
    lastname: '',
    email: '',
    phone: '',
    suspend: '',
    roles: [],
    grade: "",
    current_prise: 0,
    use_prise: 0,
    club: 0,
    marriage_at: null,
    born_at: null,

}
export const login = (state = {}, action) => {
    switch (action.type) {
        case "CheckPhone": {
            if (action.payload.token)
                return {
                    CheckPhoneToken: action.payload.token,
                    CheckPhoneStatus: action.payload.status
                }
            else
                return {
                    CheckPhoneError: action.payload
                }
        }
        case "LOGIN": {
            let user;
            try {
                user = jwtDecode(action.payload);
                return {
                    ...initialState,
                    token: user.token,
                    user_id: user.user_id,
                    name: user.name,
                    lastname: user.lastname,
                    email: user.email,
                    phone: user.phone,
                    country_code: user.country_code,
                    photo: user.profile_photo_link,
                    suspend: user.suspend,
                    grade: user?.grade,
                    current_prise: user?.current_prise,
                    use_prise: user?.use_prise,
                    club: user?.club,
                    marriage_at: user?.marriage_at,
                    born_at: user?.born_at,
                    // roles: customer.roles
                    roles: user.role
                };
            } catch (e) {
                localStorage.removeItem("token");
                return state;
            }
        }
        case "ERROR": {
            return {
                Error: action.payload
            }
        }
        default: {
            return state
        }
    }
}
