import {combineReducers} from "redux"
import {login} from "./auth/loginReducer";
import {ui} from "./UI/uiReducer";
import {cart} from "./cart/cartReducer";
import {loadingBarReducer} from "react-redux-loading-bar";
import customizer from "./customizer/customizer";

const appReducer = combineReducers({
    auth: login,
    UI: ui,
    cart: cart,
    customizer: customizer,
    loadingBar: loadingBarReducer
});

const rootReducer = (state, action) => {
    if (action.type === "LOGOUT")
        state.auth = undefined;

    return appReducer(state, action);
};

export default rootReducer
